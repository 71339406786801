import React, { useState } from 'react';
import { ArrowRight, Activity, Shield, Zap, Check, BarChart, Globe, Lock, Mail, Sparkles, BrainCircuit, Workflow, GitBranch } from 'lucide-react';

const LandingPage = () => {
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    try {
      const emailContent = `New waitlist signup: ${email}`;
      const mailtoLink = `mailto:andrewtaywq3@gmail.com?subject=New Waitlist Signup&body=${encodeURIComponent(emailContent)}`;
      window.location.href = mailtoLink;
      setSubmitStatus('success');
      setEmail('');
    } catch (error) {
      setSubmitStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white dark:from-gray-900 dark:to-gray-800">
      <main>
        {/* Hero Section */}
        <section className="relative min-h-screen flex items-center justify-center px-4 py-20">
          <div className="absolute inset-0 overflow-hidden">
            <div className="absolute inset-0 bg-blue-50 dark:bg-blue-900/20 backdrop-blur-xl"></div>
            <div className="absolute inset-0 opacity-10">
              <div className="grid grid-cols-8 h-full w-full gap-0.5">
                {[...Array(64)].map((_, i) => (
                  <div key={i} className="bg-blue-500/20"></div>
                ))}
              </div>
            </div>
          </div>
          
          <div className="relative max-w-6xl mx-auto text-center">
            <h1 className="text-5xl md:text-6xl font-bold text-gray-900 dark:text-white mb-6">
              Make Agent Development 
              <span className="block mt-2 bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-indigo-600 dark:from-blue-400 dark:to-indigo-400">
                Scientific & Reliable
              </span>
            </h1>
            <p className="text-xl md:text-2xl text-gray-700 dark:text-gray-300 mb-12 max-w-3xl mx-auto">
              Transform how you experiment, evaluate, and optimize Agents with a purpose-built platform for building better AI products.
            </p>
            <form onSubmit={handleSubmit} className="max-w-md mx-auto mb-8">
              <div className="flex flex-col sm:flex-row gap-4">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Please enter your email"
                  className="flex-grow px-6 py-4 rounded-lg border-2 border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 outline-none transition-all"
                  required
                />
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="px-8 py-4 bg-blue-600 text-white rounded-lg font-semibold flex items-center justify-center hover:bg-blue-700 transition-colors disabled:opacity-50"
                >
                  {isSubmitting ? 'Joining...' : 'Join Waitlist'}
                  <ArrowRight className="ml-2" />
                </button>
              </div>
              {/* {submitStatus === 'success' && (
                <p className="text-green-600 mt-2">Thanks for joining our waitlist!</p>
              )} */}
            </form>
          </div>
        </section>

        {/* Problem Statement Section */}
        <section className="py-24 px-4" id="problems">
          <div className="max-w-7xl mx-auto">
            <h2 className="text-4xl font-bold text-center mb-16">
              Common Challenges in Agent Development
            </h2>
            <div className="grid md:grid-cols-3 gap-8">
              <div className="bg-white dark:bg-gray-800 p-8 rounded-xl shadow-lg">
                <Activity className="w-12 h-12 text-blue-600 mb-4" />
                <h3 className="text-xl font-semibold mb-4">Time-Consuming Testing</h3>
                <p className="text-gray-600 dark:text-gray-400">
                  Manual prompt testing slows down development and reduces accuracy
                </p>
              </div>
              <div className="bg-white dark:bg-gray-800 p-8 rounded-xl shadow-lg">
                <BrainCircuit className="w-12 h-12 text-blue-600 mb-4" />
                <h3 className="text-xl font-semibold mb-4">No Standardization</h3>
                <p className="text-gray-600 dark:text-gray-400">
                  Teams lack reliable ways to evaluate performance across scenarios
                </p>
              </div>
              <div className="bg-white dark:bg-gray-800 p-8 rounded-xl shadow-lg">
                <Workflow className="w-12 h-12 text-blue-600 mb-4" />
                <h3 className="text-xl font-semibold mb-4">Limited Collaboration</h3>
                <p className="text-gray-600 dark:text-gray-400">
                  Teams struggle to collaborate effectively on prompt optimization
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Features Section */}
        <section className="bg-gray-50 dark:bg-gray-900 py-24 px-4" id="features">
          <div className="max-w-7xl mx-auto">
            <h2 className="text-4xl font-bold text-center mb-16">
              Everything You Need for Agent Excellence
            </h2>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
              <div className="bg-white dark:bg-gray-800 p-8 rounded-xl shadow-lg">
                <Sparkles className="w-12 h-12 text-blue-600 mb-4" />
                <h3 className="text-xl font-semibold mb-4">Intuitive Interface</h3>
                <ul className="space-y-3 text-gray-600 dark:text-gray-400">
                  <li>• Spreadsheet-like prompt testing</li>
                  <li>• Side-by-side output comparison</li>
                  <li>• Real-time collaborative editing</li>
                </ul>
              </div>
              <div className="bg-white dark:bg-gray-800 p-8 rounded-xl shadow-lg">
                <BarChart className="w-12 h-12 text-blue-600 mb-4" />
                <h3 className="text-xl font-semibold mb-4">Testing Framework</h3>
                <ul className="space-y-3 text-gray-600 dark:text-gray-400">
                  <li>• Parallel API testing</li>
                  <li>• Automated performance metrics</li>
                  <li>• In-depth response analysis</li>
                </ul>
              </div>
              <div className="bg-white dark:bg-gray-800 p-8 rounded-xl shadow-lg">
                <GitBranch className="w-12 h-12 text-blue-600 mb-4" />
                <h3 className="text-xl font-semibold mb-4">Enterprise Ready</h3>
                <ul className="space-y-3 text-gray-600 dark:text-gray-400">
                  <li>• Secure API key management</li>
                  <li>• Built-in version control</li>
                  <li>• Cost optimization features</li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        {/* CTA Section */}
        <section className="bg-blue-600 text-white py-24 px-4">
          <div className="max-w-7xl mx-auto text-center">
            <h2 className="text-4xl font-bold mb-8">Be First in Line</h2>
            <p className="text-xl mb-12">
              Join the waitlist to get early access and special launch pricing.
            </p>
            <form onSubmit={handleSubmit} className="max-w-md mx-auto">
              <div className="flex flex-col sm:flex-row gap-4">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Tell us what you want to see"
                  className="flex-grow px-6 py-4 rounded-lg border-2 border-white/20 bg-white/10 text-white placeholder-white/70 focus:border-white focus:ring-2 focus:ring-white/20 outline-none transition-all"
                  required
                />
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="px-8 py-4 bg-white text-blue-600 rounded-lg font-semibold hover:bg-blue-50 transition-colors disabled:opacity-50"
                >
                  {isSubmitting ? 'Joining...' : 'Join Waitlist'}
                </button>
              </div>
            </form>
          </div>
        </section>

        {/* Footer */}
        <footer className="bg-gray-50 dark:bg-gray-900 py-12 px-4">
          <div className="max-w-7xl mx-auto">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-8 text-center">
              <div className="flex flex-col items-center">
                <Shield className="w-8 h-8 text-blue-600 mb-2" />
                <p className="font-semibold">Enterprise Grade</p>
              </div>
              <div className="flex flex-col items-center">
                <Lock className="w-8 h-8 text-blue-600 mb-2" />
                <p className="font-semibold">Secure by Design</p>
              </div>
              <div className="flex flex-col items-center">
                <Globe className="w-8 h-8 text-blue-600 mb-2" />
                <p className="font-semibold">24/7 Support</p>
              </div>
              <div className="flex flex-col items-center">
                <Activity className="w-8 h-8 text-blue-600 mb-2" />
                <p className="font-semibold">99.9% Uptime</p>
              </div>
            </div>
          </div>
        </footer>
      </main>
    </div>
  );
};

export default LandingPage;